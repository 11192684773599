@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html * {
  font-family: Roboto, Open-Sans, Helvetica, Sans-Serif;
  font-size: 1.2rem;
}

button {
  line-height: reset;
}

h3, ul, li, p {
  line-height: 1.6em;
}

@media(max-width: 600px) {
  html * {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.2em;
  }
}

.override-default:link, 
.override-default:visited,
.override-default:hover,
.override-default:active {
  text-decoration: none;
  color: inherit;
  font-weight: bolder;
}
